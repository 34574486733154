function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('p',[_vm._v("git  add  .  // 提交到暂存区\ngit  commit  -m \"开发：xx功能完成\"  // 提交到本地仓库\ngit  reset --soft HEAD^\n// 这里的 HEAD^ 意思是回退到上一个版本，可以写成 HEAD~1\n// 如果 commit 了两次，两次都想回退，可以写成 HEAD~2\n//.gitignore只能忽略那些原来没有被track的文件，如果某些文件已经被纳入了版本管理中，则修改.gitignore是无效的。\n// 解决方法就是先把本地缓存删除（改变成未track状态），然后再提交\ngit rm -r --cached .\ngit add .\ngit commit -m 'update .gitignore'\n// .gitignore规则\nbin/: 忽略当前路径下的bin文件夹，该文件夹下的所有内容都会被忽略，不忽略 bin 文件\n/bin: 忽略根目录下的bin文件\n/"),_c('em',[_vm._v(".c: 忽略 cat.c，不忽略 build/cat.c\ndebug/")]),_vm._v(".obj: 忽略 debug/io.obj，不忽略 debug/common/io.obj 和 tools/debug/io.obj\n"),_c('strong',[_vm._v("/foo: 忽略/foo, a/foo, a/b/foo等\na/")]),_vm._v("/b: 忽略a/b, a/x/b, a/x/y/b等\n!/bin/run.sh: 不忽略 bin 目录下的 run.sh 文件\n*.log: 忽略所有 .log 文件\nconfig.php: 忽略当前路径的 config.php 文件")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }